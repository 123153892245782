<template>
  <Popup ref="popup">
    <div class="card">
      <!-- <div class="title" v-if="title">{{ title }}</div> -->
      <div v-if="showAnimation" id="lottie"></div>
      <div class="text">
        <div class="title" v-if="message">{{ message }}</div>
        <div class="title error" v-else-if="error">{{ error }}</div>
      </div>
      <div class="buttons">
        <div id="btn" v-if="button" v-bind:class="btnClass" @click="onButtonTapped()">{{ button }}</div>
      </div>
    </div>
  </Popup>
</template>

<script>
import lottie from "lottie-web";
import stowyLogoAnimation from "../assets/lottie/loader-stowy.json";
import Popup from "./Popup.vue";

export default {
  name: "WipPopup",
  components: { Popup },
  data: () => ({
    title: undefined,
    message: undefined,
    style: "info", // 'warning' || 'error' || 'success'
    showAnimation: true,
    animated: false,
    animationLoop: true,
    error: undefined,
    button: "", // impotant for component to render at first init
    buttonCallback: undefined,
    isButtonEnabled: false,
    resolvePromise: undefined,
    rejectPromise: undefined,
    isOpen: false,
    animation: null,
    // btnElement: null,
    btnClass: null,
    btnEnabledClass: "btn btn-secondary",
    btnDisabledClass: "btn btn-secondary disabled",
    animationFrames: {
      blue: {
        start: 0,
        end: 12
      },
      green: {
        start: 19,
        end: 29
      },
      yellow: {
        start: 40,
        end: 48
      },
      purple: {
        start: 60,
        end: 67
      },
      orange: {
        start: 78,
        end: 88
      },
      full: {
        start: 0,
        end: 99
      }
    }
  }),
  mounted() {
    this.btnClass = this.btnEnabledClass;
  },
  updated() {
    //this.btnElement = document.getElementById("btn");
  },
  methods: {
    setButtonClass() {
      // if (this.btnElement) {
      this.btnClass = this.isButtonEnabled
        ? this.btnEnabledClass
        : this.btnDisabledClass;

      console.log(this.btnClass);
      // this.btnElement.class = this.btnClass;
      // this.btnElement.className = this.btnClass;
      // }
    },
    animationFramesForStyle(style) {
      if (style === "info") {
        return this.animationFrames.blue;
      } else if (style === "success") {
        return this.animationFrames.orange;
      } else if (style === "warning") {
        return this.animationFrames.yellow;
      } else if (style === "error") {
        return this.animationFrames.yellow;
      } else if (style === "stowy") {
        return this.animationFrames.purple;
      } else if (style === "loading") {
        return this.animationFrames.full;
      }
    },

    onButtonTapped() {
      if (!this.isButtonEnabled) {
        return;
      }
      if (this.buttonCallback) {
        this.buttonCallback();
      } else {
        // Default action
        this.close();
      }
    },

    configure(data) {
      this.title = data.title;
      this.message = data.message;
      this.style = data.style || "info";
      this.error = data.error;
      this.button = data.button || null;
      this.buttonCallback = data.buttonCallback;
      this.isButtonEnabled = data.isButtonEnabled || false;
      this.showAnimation = data.showAnimation || false;
      this.animated = data.animated || false;
      this.animationLoop = data.animationLoop || true;

      this.setButtonClass();

      if (this.showAnimation) {
        setTimeout(() => {
          if (!this.animation) {
            this.animation = lottie.loadAnimation({
              container: document.getElementById("lottie"), // Required
              animationData: stowyLogoAnimation, // Required
              renderer: "svg", // Required
              loop: this.animationLoop, // Optional
              autoplay: false // Optional
            });
          }
          const frames = this.animationFramesForStyle(this.style);
          this.animation.playSegments([frames.start, frames.end], true);
          if (!this.animated) {
            this.animation.pause();
          }
        }, 20);
      } else {
        if (this.animation) {
          this.animation.destroy();
          this.animation = null;
        }
      }
    },

    update(data) {
      this.configure(data);

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    show(data) {
      this.configure(data);

      if (!this.isOpen) {
        this.$refs.popup.open();
        this.isOpen = true;
      }

      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    close() {
      this.animation = null;
      this.$refs.popup.close();
      this.isOpen = false;
      this.resolvePromise(true);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@style/common/_popup";
</style>