<template>
  <div class="cgu">
    <!-- CGU -->
    <div class="container-fluid page-main">
      <div style="height: 100px;"></div>
      <h1>{{ messageForKey('cgu.title')}}</h1>
      <div style="height: 120px;"></div>
      <div v-for="cguKey in allCguKeys" :key="cguKey">
        <div v-if="styleForKey(cguKey) === paragraphStyle">
          <p>{{ messageForKey(`${cguKey}`)}}</p>
        </div>
        <div v-else-if="styleForKey(cguKey) === subtitleStyle">
          <div style="height: 40px;"></div>
          <h2>{{ messageForKey(`${cguKey}`)}}</h2>
          <div style="height: 10px;"></div>
        </div>
        <div v-else-if="styleForKey(cguKey) === titleStyle">
          <div style="height: 80px;"></div>
          <h1>{{ messageForKey(`${cguKey}`)}}</h1>
          <div style="height: 20px;"></div>
        </div>
      </div>
      <!-- CGV -->
      <div style="height: 300px;"></div>
      <h1>{{ messageForKey('cgv.title')}}</h1>
      <div style="height: 120px;"></div>
      <div v-for="cgvKey in allCgvKeys" :key="cgvKey">
        <div v-if="styleForKey(cgvKey) === paragraphStyle">
          <p>{{ messageForKey(`${cgvKey}`)}}</p>
        </div>
        <div v-else-if="styleForKey(cgvKey) === subtitleStyle">
          <div style="height: 40px;"></div>
          <h2>{{ messageForKey(`${cgvKey}`)}}</h2>
          <div style="height: 10px;"></div>
        </div>
        <div v-else-if="styleForKey(cgvKey) === titleStyle">
          <div style="height: 80px;"></div>
          <h1>{{ messageForKey(`${cgvKey}`)}}</h1>
          <div style="height: 20px;"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      titleStyle: "h2",
      subtitleStyle: "p",
      paragraphStyle: "p"
    };
  },
  computed: {
    allCguKeys() {
      var ks = Object.keys(this.$i18n.messages.en.cgu).map(k => `cgu.${k}`);
      ks.shift();
      return ks;
    },
    allCgvKeys() {
      var ks = Object.keys(this.$i18n.messages.en.cgv).map(k => `cgv.${k}`);
      ks.shift();
      return ks;
    }
  },
  methods: {
    messageForKey(key) {
      return this.$i18n.t(key);
    },
    styleForKey(key) {
      if (typeof key !== "string") {
        return;
      }

      if (key.endsWith("subtitle")) {
        return this.subtitleStyle;
      } else if (key.endsWith("title")) {
        return this.titleStyle;
      }
      return this.paragraphStyle;
    }
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
.cgu {
  h2 {
    color: #3b3f4a;
    font-size: 50px;
    font-weight: 500;
    line-height: 1.2;
    font-family: "Baloo 2", sans-serif;
  }
  h3 {
    font-size: 40px;
    font-weight: 500;
    line-height: 1.3;
    font-family: "Baloo 2", sans-serif;
  }
  .page-main {
    padding: 36px;
  }
  .page-main h1 {
    text-align: center;
  }
}
</style>