import Vue from 'vue'

import App from './AppPrelaunch.vue'
import AppEmpty from './AppEmpty.vue'
import router from './routes'
import store from './store'
import './logger'

import { auth, usersCollection } from './firebase'
import i18n from './i18n'

const isProduction = process.env.NODE_ENV === 'production';
Vue.config.productionTip = isProduction

const init = function () {

  let app = null;

  let loading = new Vue({
    render: h => h(AppEmpty)
  }).$mount('#app')

  auth.signInAnonymously();
  auth.onAuthStateChanged(user => {
    if (user && user.uid) {
      store.commit('setAuthUser', user);

      let unsubscribe = usersCollection.doc(user.uid) // No need to unsub here
        .onSnapshot(snapshot => {
          Vue.$log.debug("Build:", isProduction ? 'production' : 'development');

          if (!app) {
            app = new Vue({
              router,
              store,
              i18n,
              render: h => h(App)
            }).$mount('#app')
          }

          if (snapshot.data()) {
            store.commit('setUserProfile', snapshot.data());
          }

        })
    }
  });
}

init();