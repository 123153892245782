<template>
  <div id="app" class="container-fluid">
    <!-- Lottie presentation code, depending on payment state -->
    <div class="main-content">
      <!-- Header with title & video -->
      <header class="main-head">
        <div class="section-wrapper">
          <h1 class="header__logo">
            <img src="../assets/img/logo-stowy.png" alt="Stowy" />
          </h1>

          <h2 class="main-head__title">
            <p>
              <span id="kidName"></span>
              {{ $i18n.t("home.head-title") }}
            </p>
          </h2>

          <h3 class="main-head__descr">
            <span>{{ $i18n.t("home.head-desc-1") }}</span>
            {{ $i18n.t("home.head-desc-2") }}
          </h3>

          <div class="main-head__video">
            <iframe
              src="https://www.youtube.com/embed/0si0C9EBxIk?rel=0"
              title="Stowy Video"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>

          <p class="main-head__callout-btns">
            <a
              href="#bubble-points"
              class="btn btn-secondary btn-L"
              id="btn-know-more-1"
            >{{ $i18n.t("global.know-more") }}</a>

            <a 
              href="#stowy-plans" 
              class="btn btn-primary btn-L"
              id="btn-im-interested-1"
            >
              <span>{{ $i18n.t("global.im-interested") }}</span>
            </a>
          </p>

          <div class="starry">
            <img src="../assets/img/starry.png" alt />
          </div>
        </div>
      </header>

      <!-- Points with bubbles & illustrations -->
      <section class="bubble-points" id="bubble-points">
        <h2 class="section-title">{{ $i18n.t("home.bubble-points-section-title") }}</h2>

        <article class="bubble-point bubble-point-kid">
          <div class="bubble-point__wrapper">
            <blockquote class="bubble-point__main">
              <q>{{ $i18n.t("home.bubble-points-quoted-1") }}</q>
            </blockquote>
            <p class="bubble-point__secondary">{{ $i18n.t("home.bubble-points-quoted-1-desc") }}</p>
          </div>
        </article>

        <article class="bubble-point bubble-point-mum">
          <div class="bubble-point__wrapper">
            <blockquote class="bubble-point__main">
              <q>{{ $i18n.t("home.bubble-points-quoted-2") }}</q>
            </blockquote>
            <p class="bubble-point__secondary">{{ $i18n.t("home.bubble-points-quoted-2-desc") }}</p>
          </div>
        </article>

        <article class="bubble-point bubble-point-lgbt">
          <div class="bubble-point__wrapper">
            <blockquote class="bubble-point__main">
              <q>{{ $i18n.t("home.bubble-points-quoted-3") }}</q>
            </blockquote>
            <p class="bubble-point__secondary">
              {{ $i18n.t("home.bubble-points-quoted-3-desc") }}
              <br />
              <strong>{{ $i18n.t("home.bubble-points-quoted-3-addon") }}</strong>
            </p>
          </div>
        </article>

        <p class="bubble-point__callout-wrapper">
          <a 
            href="#stowy-plans" 
            class="btn btn-primary btn-L bubble-point__callout-btn"
            id="btn-callout-1">
            <span>{{ $i18n.t("global.support-us") }}</span>
          </a>
        </p>
      </section>

      <!-- 3 blocks with long scrolling visual -->
      <section class="how-why-who">
        <div class="section-wrapper">
          <div class="how">
            <header class="how__head">
              <h2 class="section-title is-purple">{{ $i18n.t("home.how-why-1") }}</h2>
              <a
                href="#stowy-plans"
                class="btn-noBgd how__callout-btn"
                id="btn-im-interested-2"
              >{{ $i18n.t("global.im-interested") }}</a>
            </header>

            <div id="how__tabs">
              <ul class="how__tabs-nav">
                <li>
                  <a href="#tabs-1">
                    <span class="how__tabs-nav-number">1</span>
                    <span class="how__tabs-nav-text">{{ $i18n.t("home.how-why-2") }}</span>
                  </a>
                </li>

                <li>
                  <a href="#tabs-2">
                    <span class="how__tabs-nav-number">2</span>
                    <span class="how__tabs-nav-text">{{ $i18n.t("home.how-why-3") }}</span>
                  </a>
                </li>

                <li>
                  <a href="#tabs-3">
                    <span class="how__tabs-nav-number">3</span>
                    <span class="how__tabs-nav-text">{{ $i18n.t("home.how-why-4") }}</span>
                  </a>
                </li>

                <li>
                  <a href="#tabs-4">
                    <span class="how__tabs-nav-number">4</span>
                    <span class="how__tabs-nav-text">{{ $i18n.t("home.how-why-5") }}</span>
                  </a>
                </li>
              </ul>

              <div id="tabs-1" class="how__tab how__tab-1">
                <img class="how-tab__visual" src="../assets/img/how__visual-1@2x.jpg" alt />
              </div>

              <div id="tabs-2" class="how__tab how__tab-2">
                <img class="how-tab__visual" src="../assets/img/how__visual-2@2x.jpg" alt />
              </div>

              <div id="tabs-3" class="how__tab how__tab-3">
                <img class="how-tab__visual" src="../assets/img/how__visual-3@2x.jpg" alt />
              </div>

              <div id="tabs-4" class="how__tab how__tab-4">
                <img class="how-tab__visual" src="../assets/img/how__visual-4@2x.jpg" alt />
              </div>

              <div class="clear"></div>
            </div>

            <p class="how__callout-btn--mobile">
              <a href="#stowy-plans" 
              class="btn-noBgd"
              id="btn-im-interested-2"
              >{{ $i18n.t("global.im-interested") }}</a>
            </p>
          </div>

          <div class="why">
            <h2
              class="section-title section-title--center is-orange"
            >{{ $i18n.t("home.why-trust") }}</h2>

            <ul class="why__list">
              <li class="why__point">
                <div class="rounded-image">
                  <img src="../assets/img/pedagogique.png" alt="Lettres pédagogiques" />
                </div>
                <h4>{{ $i18n.t("home.why-pedagogic-1") }}</h4>
                <p>{{ $i18n.t("home.why-pedagogic-2") }}</p>
                <p>{{ $i18n.t("home.why-pedagogic-3") }}</p>
              </li>

              <li class="why__point">
                <div class="rounded-image">
                  <img src="../assets/img/familles.png" alt="Famille" />
                </div>
                <h4>{{ $i18n.t("home.why-family-1") }}</h4>
                <p>{{ $i18n.t("home.why-family-2") }}</p>
                <p>{{ $i18n.t("home.why-family-3") }}</p>
              </li>

              <li class="why__point">
                <div class="rounded-image">
                  <img src="../assets/img/madeinfrance.png" alt="Made in France" />
                </div>
                <h4>{{ $i18n.t("home.why-mif-1") }}</h4>
                <p>{{ $i18n.t("home.why-mif-2") }}</p>
                <p>{{ $i18n.t("home.why-mif-3") }}</p>
              </li>
            </ul>

            <p class="why__callout-wrapper">
              <a
                href="#stowy-plans"
                class="btn-noBgd why__callout-btn"
                id="btn-im-with-you"
              >{{ $i18n.t("global.im-with-you") }}</a>
            </p>
          </div>

          <div class="who">
            <h2 class="section-title section-title--right">{{ $i18n.t("home.who-1") }}</h2>

            <p class="who__excerpt">{{ $i18n.t("home.who-2") }}</p>

            <ul class="who__cards">
              <li class="who__card who__card-blue who__card-kevin">
                <div class="who__card-wrapper">
                  <div class="who__card-front">
                    <div class="who-card__avatar"></div>
                    <div class="who-card__main-info">
                      <h5 class="who-card__name">{{ $i18n.t("home.who-kevin-name") }}</h5>
                      <h6 class="who-card__role">{{ $i18n.t("home.who-kevin-role") }}</h6>
                    </div>
                  </div>
                  <div class="who__card-back">
                    <p>{{ $i18n.t("home.who-kevin-1") }}</p>
                    <p>{{ $i18n.t("home.who-kevin-2") }}</p>
                  </div>
                </div>
              </li>

              <li class="who__card who__card-orange who__card-sebastien">
                <div class="who__card-wrapper">
                  <div class="who__card-front">
                    <div class="who-card__avatar"></div>
                    <div class="who-card__main-info">
                      <h5 class="who-card__name">{{ $i18n.t("home.who-seb-name") }}</h5>
                      <h6 class="who-card__role">{{ $i18n.t("home.who-seb-role") }}</h6>
                    </div>
                  </div>
                  <div class="who__card-back">
                    <p>{{ $i18n.t("home.who-seb-1") }}</p>
                    <p>{{ $i18n.t("home.who-seb-2") }}</p>
                  </div>
                </div>
              </li>

              <li class="who__card who__card-green who__card-rachel">
                <div class="who__card-wrapper">
                  <div class="who__card-front">
                    <div class="who-card__avatar"></div>
                    <div class="who-card__main-info">
                      <h5 class="who-card__name">{{ $i18n.t("home.who-rachel-name") }}</h5>
                      <h6 class="who-card__role">{{ $i18n.t("home.who-rachel-role") }}</h6>
                    </div>
                  </div>
                  <div class="who__card-back">
                    <p>{{ $i18n.t("home.who-rachel-1") }}</p>
                    <p>{{ $i18n.t("home.who-rachel-2") }}</p>
                  </div>
                </div>
              </li>

              <li class="who__card who__card-purple who__card-lanternes">
                <div class="who__card-wrapper">
                  <div class="who__card-front">
                    <div class="who-card__avatar"></div>
                    <div class="who-card__main-info">
                      <h5 class="who-card__name">{{ $i18n.t("home.who-lanternes-name") }}</h5>
                      <h6 class="who-card__role">{{ $i18n.t("home.who-lanternes-role") }}</h6>
                    </div>
                  </div>
                  <div class="who__card-back">
                    <p>{{ $i18n.t("home.who-lanternes-1") }}</p>
                    <p>{{ $i18n.t("home.who-lanternes-2") }}</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <!-- Different plans to suscribe -->
      <section class="plans" id="stowy-plans">
        <div class="section-wrapper">
          <header class="plans__header">
            <h5>{{ $i18n.t("home.roadmap-1") }}</h5>
            <div class="subtitle">
              <h3>
                {{ $i18n.t("home.roadmap-2") }}
                <strong>{{ $i18n.t("home.roadmap-3") }}</strong>
                {{ $i18n.t("home.roadmap-4") }}
              </h3>
            </div>
            <div></div>
          </header>

          <div class="plans__list">
            <article id="plan-beta-tester" class="plan plan-purple" v-on:click="onSelect(0)">
              <div class="plan__wrapper">
                <div class="plan__radio-btn"></div>
                <h3 class="plan__title">{{ $i18n.t("home.plan-free-1") }}</h3>
                <ul class="plan__offer">
                  <li>{{ $i18n.t("home.plan-free-2") }}</li>
                  <li>{{ $i18n.t("home.plan-free-3") }}</li>
                </ul>
                <footer class="plan__price">{{ $i18n.t("home.plan-free-4") }}</footer>
              </div>
            </article>

            <article id="plan-solo" class="plan plan-blue" v-on:click="onSelect(1)">
              <div class="plan__wrapper">
                <div class="plan__radio-btn"></div>
                <h3 class="plan__title">{{ $i18n.t("home.plan-solo-1") }}</h3>
                <ul class="plan__offer">
                  <li>{{ $i18n.t("home.plan-solo-2") }}</li>
                  <li>{{ $i18n.t("home.plan-solo-3") }}</li>
                  <li>{{ $i18n.t("home.plan-solo-4") }}</li>
                </ul>
                <footer class="plan__price">
                  {{ $i18n.t("home.plan-solo-5") }}
                  <span
                    class="plan__price-info"
                  >{{ $i18n.t("home.plan-solo-6") }}</span>
                </footer>
              </div>
            </article>

            <article id="plan-family" class="plan plan-orange" v-on:click="onSelect(2)">
              <div class="plan__wrapper">
                <div class="plan__radio-btn"></div>
                <h3 class="plan__title">{{ $i18n.t("home.plan-family-1") }}</h3>
                <ul class="plan__offer">
                  <li>{{ $i18n.t("home.plan-family-2") }}</li>
                  <li>{{ $i18n.t("home.plan-family-3") }}</li>
                  <li>{{ $i18n.t("home.plan-family-4") }}</li>
                </ul>
                <footer class="plan__price">
                  {{ $i18n.t("home.plan-family-5") }}
                  <span
                    class="plan__price-info"
                  >{{ $i18n.t("home.plan-family-6") }}</span>
                </footer>
              </div>
            </article>
          </div>

          <!-- 
                Classes possibles:
                - .is-active
                - .is-disabled
                - .has-error
          -->
          <form class="suscribe-form" @submit.prevent="proceed">
            <ul class="suscribe-from__inputs">
              <li class="suscribe-form__prenom-wrapper">
                <input
                  type="text"
                  id="suscribe-form__prenom"
                  class="suscribe-form__email"
                  :placeholder="$i18n.t('home.placeholder-firstname')"
                  required
                  v-if="userSession.profile"
                  v-model="userSession.profile.firstName"
                  :disabled="!shouldEnableRegisterButton"
                />
                <span class="input__gradient-bgd"></span>
              </li>
              <li class="suscribe-form__nom-wrapper">
                <input
                  type="text"
                  id="suscribe-form__nom"
                  class="suscribe-form__email"
                  :placeholder="$i18n.t('home.placeholder-lastname')"
                  required
                  v-if="userSession.profile"
                  v-model="userSession.profile.lastName"
                  :disabled="!shouldEnableRegisterButton"
                />
                <span class="input__gradient-bgd"></span>
              </li>
              <li class="suscribe-form__email-wrapper">
                <input
                  type="email"
                  id="suscribe-form__email"
                  class="suscribe-form__email"
                  :placeholder="$i18n.t('home.placeholder-email')"
                  required
                  v-if="userSession.profile"
                  v-model="userSession.profile.email"
                  :disabled="!shouldEnableRegisterButton"
                />
                <span class="input__gradient-bgd"></span>
              </li>
            </ul>

            <p class="suscribe-form__cgv-wrapper">
              <input
                type="checkbox"
                id="suscribe-form__CGV"
                class="cursor-pointer"
                v-model="isCgvChecked"
              />
              <label class="cursor-pointer" for="suscribe-form__CGV">
                {{ $i18n.t("home.cgv-checkbox-1") }}
                <router-link
                  :to="{ name: 'Cgu'}"
                  target="_blank"
                >{{ $i18n.t("home.cgv-checkbox-2") }}</router-link>
              </label>
            </p>

            <button
              id="suscribe-form__btn"
              class="btn btn-primary btn-M"
              v-if="userSession.profile"
            >
              <span>{{ $i18n.t("global.i-signup") }}</span>
            </button>
          </form>

          <div style="height: 26px;" class="error-message">{{ userSession.error || '' }}</div>
        </div>
      </section>

      <footer class="stowy-footer">
        <div class="section-wrapper">
          <ul class="footer__left">
            <li>
              <router-link
                :to="{ name: 'Legals'}"
                target="_blank"
              >{{ $i18n.t("global.legals-term") }}</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Cgu'}" target="_blank">{{ $i18n.t("global.cgv-cgu-term") }}</router-link>
            </li>
            <li>
              <a href="mailto:kevin@stowy.fr">{{ $i18n.t("home.contact-address") }}</a>
            </li>
          </ul>

          <div class="footer__center">
            <img class="footer__logo" src="../assets/img/logo-stowy.png" alt="Stowy" />
            <p class="footer__social">
              <a
                href="https://www.facebook.com/StowyFR"
                target="_blank"
                class="footer__social-btn footer__facebook"
              ></a>

              <a
                href="https://www.twitter.com/StowyFR"
                target="_blank"
                class="footer__social-btn footer__twitter"
              ></a>
              <a
                href="https://www.instagram.com/StowyFR"
                target="_blank"
                class="footer__social-btn footer__insta"
              ></a>
            </p>
          </div>

          <p class="footer__copyright">
            {{ $i18n.t("home.copyright-title") }}
            <br />
            {{ $i18n.t("home.copyright-1") }}
            <br />
            {{ $i18n.t("home.copyright-2") }}
          </p>
        </div>
      </footer>

      <!-- 
        Classe à ajouter sur la classe .cookies: 
        - Pour afficher le moe "Configurer": .is-expanded
        - Une fois que les cookies sont validés: .is-accepted
      -->
      <div id="cookies" class="cookies" v-if="!userSession.cookies.acted">
        <div class="cookies__wrapper">
          <h3 class="cookies__title">{{ $i18n.t("home.cookies-title") }}</h3>
          <p class="cookies__intro">{{ $i18n.t("home.cookies-intro") }}</p>

          <div class="cookies--short">
            <ul class="cookies-short__options">
              <li>
                <input type="checkbox" checked v-model="userSession.cookies.essentials" />
                <label>{{ $i18n.t("home.cookies-option-1") }}</label>
              </li>
              <li>
                <input type="checkbox" checked v-model="userSession.cookies.functionals" />
                <label>{{ $i18n.t("home.cookies-option-2") }}</label>
              </li>
              <li>
                <input type="checkbox" checked v-model="userSession.cookies.ads" />
                <label>{{ $i18n.t("home.cookies-option-3") }}</label>
              </li>
            </ul>

            <div class="cookies__call-btns">
              <a class="btn btn-secondary" v-on:click="onExpandCookies()">
                <span>{{ $i18n.t("home.cookies-button-configure") }}</span>
              </a>
              <a class="btn btn-primary" v-on:click="onAcceptCookies(true, true, true);">
                <span>{{ $i18n.t("home.cookies-button-accept") }}</span>
              </a>
            </div>
          </div>

          <div class="cookies--long">
            <h4>{{ $i18n.t("home.cookies-essentials-title") }}</h4>
            <p>{{ $i18n.t("home.cookies-essentials-desc") }}</p>

            <ul>
              <li class="cookies__section">
                <input type="checkbox" checked v-model="userSession.cookies.essentials" />
                <h5>{{ $i18n.t("home.cookies-essentials-stowy-desc-1") }}</h5>
                <p>{{ $i18n.t("home.cookies-essentials-stowy-desc-2") }}</p>

                <p>{{ $i18n.t("home.cookies-essentials-stowy-desc-3") }}</p>

                <h6>{{ $i18n.t("home.cookies-essentials-stowy-desc-4") }}</h6>
                <p>{{ $i18n.t("home.cookies-essentials-stowy-desc-5") }}</p>

                <h6>{{ $i18n.t("home.cookies-essentials-stowy-desc-6") }}</h6>
                <ul>
                  <li>{{ $i18n.t("home.cookies-essentials-stowy-desc-7") }}</li>
                  <li>{{ $i18n.t("home.cookies-essentials-stowy-desc-8") }}</li>
                </ul>

                <h6>{{ $i18n.t("home.cookies-essentials-stowy-desc-9") }}</h6>
                <ul>
                  <li>{{ $i18n.t("home.cookies-essentials-stowy-desc-10") }}</li>
                  <li>{{ $i18n.t("home.cookies-essentials-stowy-desc-11") }}</li>
                </ul>
              </li>

              <li class="cookies__section">
                <input type="checkbox" checked v-model="userSession.cookies.functionals" />
                <h5>{{ $i18n.t("home.cookies-essentials-gtm-desc-1") }}</h5>
                <p>{{ $i18n.t("home.cookies-essentials-gtm-desc-2") }}</p>

                <p>{{ $i18n.t("home.cookies-essentials-gtm-desc-3") }}</p>

                <h6>{{ $i18n.t("home.cookies-essentials-gtm-desc-4") }}</h6>
                <p>{{ $i18n.t("home.cookies-essentials-gtm-desc-5") }}</p>

                <h6>{{ $i18n.t("home.cookies-essentials-gtm-desc-6") }}</h6>
                <p>{{ $i18n.t("home.cookies-essentials-gtm-desc-7") }}</p>

                <h6>{{ $i18n.t("home.cookies-essentials-gtm-desc-8") }}</h6>
                <p>{{ $i18n.t("home.cookies-essentials-gtm-desc-9") }}</p>
              </li>
            </ul>

            <h4>{{ $i18n.t("home.cookies-functionals-title") }}</h4>
            <ul>
              <li class="cookies__section">
                <input type="checkbox" checked v-model="userSession.cookies.ads" />
                <h5>{{ $i18n.t("home.cookies-functionals-ga-desc-1") }}</h5>
                <p>{{ $i18n.t("home.cookies-functionals-ga-desc-2") }}</p>

                <p>{{ $i18n.t("home.cookies-functionals-ga-desc-3") }}</p>

                <h6>{{ $i18n.t("home.cookies-functionals-ga-desc-4") }}</h6>
                <p>{{ $i18n.t("home.cookies-functionals-ga-desc-5") }}</p>

                <h6>{{ $i18n.t("home.cookies-functionals-ga-desc-6") }}</h6>
                <p>{{ $i18n.t("home.cookies-functionals-ga-desc-7") }}</p>

                <h6>{{ $i18n.t("home.cookies-functionals-ga-desc-8") }}</h6>
                <p>{{ $i18n.t("home.cookies-functionals-ga-desc-9") }}</p>
              </li>
            </ul>

            <div class="cookies__call-btns">
              <a class="btn btn-secondary" v-on:click="onAcceptCookies(true, true, true);">
                <span>{{ $i18n.t("home.cookies-button-accept-all") }}</span>
              </a>
              <a
                class="btn btn-primary"
                v-on:click="onAcceptCookies(userSession.cookies.essentials, userSession.cookies.functionals, userSession.cookies.ads);"
              >
                <span>{{ $i18n.t("home.cookies-button-accept-selection") }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <locale-switcher v-if="isDev" />
    <info-popup ref="infoPopup"></info-popup>
  </div>
</template>

<script>
import InfoPopup from "../components_prelaunch/InfoPopup.vue";
import LocaleSwitcher from "../components_prelaunch/LocaleSwitcher.vue";
import VueCookieAcceptDecline from "vue-cookie-accept-decline";
import { mapState } from "vuex";

export default {
  name: "Home",
  components: {
    InfoPopup,
    LocaleSwitcher,
    VueCookieAcceptDecline
  },
  data() {
    return {
      options: ["free", "solo", "family"],
      selectedOptionIndex: null,
      isCgvChecked: false,
      errors: []
    };
  },
  computed: {
    ...mapState(["userSession"]),
    isDev() {
      return process.env.NODE_ENV === "development";
    },
    isEmailValid() {
      return (
        this.userSession.profile &&
        this.userSession.profile.email &&
        this.validateEmail(this.userSession.profile.email)
      );
    },
    isFirstNameValid() {
      return (
        this.userSession.profile &&
        this.userSession.profile.firstName &&
        this.validateName(this.userSession.profile.firstName)
      );
    },
    isLastNameValid() {
      return (
        this.userSession.profile &&
        this.userSession.profile.lastName &&
        this.validateName(this.userSession.profile.lastName)
      );
    },
    shouldEnableRegisterButton() {
      return !(
        this.userSession.auth.signingIn ||
        this.userSession.payment.awaiting ||
        this.userSession.payment.pending
      );
    },
    defaultSelectedOptionIndex() {
      // If user selected something manually, returns his selection first
      if (this.selectedOptionIndex != null) {
        return this.selectedOptionIndex;
        // Returns user saved choice second
      } else if (
        this.userSession.profile &&
        this.userSession.profile.stripeRole
      ) {
        //Todo code a refaire avec un util ou classe
        return this.userSession.profile.stripeRole === "free"
          ? 0
          : this.userSession.profile.stripeRole === "solo"
          ? 1
          : 2;
      }
      // Returns middle one by default
      return 1;
    }
  },
  updated() {
    // Refresh selected option when state change
    this.onSelect(this.defaultSelectedOptionIndex);
  },
  mounted() {
    this.userSession.cookies.essentials =
      JSON.parse(localStorage.getItem("cookies.essentials")) || true;
    this.userSession.cookies.functionals =
      JSON.parse(localStorage.getItem("cookies.functionals")) || true;
    this.userSession.cookies.ads =
      JSON.parse(localStorage.getItem("cookies.ads")) || true;
    this.userSession.cookies.acted =
      JSON.parse(localStorage.getItem("cookies.acted")) || false;

    const inject = () => {
      this.selectedOptionIndex = this.defaultSelectedOptionIndex;
      this.onSelect(this.selectedOptionIndex); // Selects the solo option by default or user paid option

      if (process.env.VUE_APP_ENVIRONMENT == "development") {
        let meta = document.createElement("meta");
        meta.name = "robots";
        meta.content = "noindex";
        document.head.appendChild(meta);
      } else {
        var oldhsscript = document.getElementById("hs-script-loader");
        if (oldhsscript) {
          document.head.removeChild(oldhsscript);
        }
        var hsscript = document.createElement("script");
        hsscript.id = "hs-script-loader";
        hsscript.src = "//js-eu1.hs-scripts.com/24991321.js";
        hsscript.defer = true;
        hsscript.async = true;
      }

      var oldgtagscript = document.getElementById("gtagscript");
      if (oldgtagscript) {
        document.head.removeChild(oldgtagscript);
      }
      var gtagscript = document.createElement("script");
      gtagscript.id = "gtagscript";
      gtagscript.setAttribute(
        "src",
        "https://www.googletagmanager.com/gtag/js?id=GTM-PMGW38M"
      );
      gtagscript.addEventListener(
        "load",
        () => {
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag("js", new Date());
        },
        { once: true }
      );
      document.head.appendChild(gtagscript);

      var oldjqueryui = document.getElementById("jqueryui");
      if (oldjqueryui) {
        document.head.removeChild(oldjqueryui);
        this.$log.debug("Removed old jqueryui script");
      }

      var jqueryui = document.createElement("script");
      jqueryui.id = "jqueryui";
      jqueryui.src = "https://code.jquery.com/ui/1.12.1/jquery-ui.js";
      jqueryui.addEventListener(
        "load",
        () => {
          $(document).ready(function($) {
            $(function() {
              $("#how__tabs")
                .tabs()
                .addClass("ui-tabs-vertical ui-helper-clearfix");
              $("#how__tabs li")
                .removeClass("ui-corner-top")
                .addClass("ui-corner-left");
            });
          });
        },
        { once: true }
      );
      document.head.appendChild(jqueryui);

      var oldjquery3script = document.getElementById("jquery3script");
      if (oldjquery3script) {
        document.head.removeChild(oldjquery3script);
        this.$log.debug("Removed old jquery3 script");
      }
      var jquery3script = document.createElement("script");
      jquery3script.id = "jquery3script";
      jquery3script.src = "https://code.jquery.com/jquery-3.6.0.js";
      jquery3script.type = "text/javascript";
      jquery3script.addEventListener(
        "load",
        () => {
          function sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
          }

          var showText = function(target, message, index, interval, direction) {
            if (direction == "forward" && index < message.length) {
              $(target).append(message[index++]);
              setTimeout(function() {
                showText(target, message, index, interval, direction);
              }, interval);
            } else if (direction == "backward" && index >= 0) {
              var str = "";
              var arr = message.split("");
              for (let j = 0; j < index; j++) {
                str = str + arr[j];
              }

              $(target).text(str);
              index--;

              setTimeout(function() {
                showText(target, message, index, interval, direction);
              }, interval);
            }
          };

          $(document).ready(async function() {
            const names = [
              "Teo",
              "Emma",
              "Gabriel",
              "Louise",
              "Raphaël",
              "Jade",
              "Adam",
              "Alice"
            ];

            while (true) {
              for (let i = 0; i < names.length; i++) {
                showText("#kidName", names[i], 0, 100, "forward");
                await sleep(2600);
                showText(
                  "#kidName",
                  names[i],
                  names[i].length - 1,
                  100,
                  "backward"
                );
                await sleep(1000);
              }
            }
          });
        },
        { once: true }
      );
      document.head.appendChild(jquery3script);
    };
    inject();
  },
  methods: {
    validateName(inputText) {
      var format = /^[a-zA-Z]+/;
      return inputText.match(format);
    },

    validateEmail(inputText) {
      var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      return inputText.match(mailformat);
    },

    onAcceptCookies(essentials, functionals, ads) {
      localStorage.setItem("cookies.essentials", JSON.stringify(essentials));
      localStorage.setItem("cookies.functionals", JSON.stringify(functionals));
      localStorage.setItem("cookies.ads", JSON.stringify(ads));
      localStorage.setItem("cookies.acted", JSON.stringify(true));
      this.onCookiesAccepted();
    },

    onCookiesAccepted() {
      document.getElementById("cookies").classList.remove("is-expanded");
      document.getElementById("cookies").classList.add("is-accepted");
    },

    onExpandCookies() {
      document.getElementById("cookies").classList.add("is-expanded");
    },

    onOpenCgu() {
      this.$router.push("/cgu");
    },

    onOpenLegals() {
      this.$router.push("/legals");
    },

    onSelect(optionIndex) {
      try {
        this.selectedOptionIndex = optionIndex;

        const freeClass = `plan plan-purple ${
          optionIndex === 0 ? "is-active" : ""
        }`;
        const soloClass = `plan plan-blue ${
          optionIndex === 1 ? "is-active" : ""
        }`;
        const familyClass = `plan plan-orange ${
          optionIndex === 2 ? "is-active" : ""
        }`;
        document.getElementById("plan-beta-tester").className = freeClass;
        document.getElementById("plan-solo").className = soloClass;
        document.getElementById("plan-family").className = familyClass;
      } catch (error) {}
    },

    async proceed() {
      if (!this.isCgvChecked) {
        this.$store.dispatch("formError", this.$i18n.t("errors.cgv-check"));
      } else if (!this.shouldEnableRegisterButton) {
        return;
      } else if (!this.isEmailValid) {
        this.$store.dispatch("formError", this.$i18n.t("errors.invalid-email"));
      } else if (!this.isFirstNameValid) {
        this.$store.dispatch(
          "formError",
          this.$i18n.t("errors.invalid-firstname")
        );
      } else if (!this.isLastNameValid) {
        this.$store.dispatch(
          "formError",
          this.$i18n.t("errors.invalid-lastname")
        );
      } else {
        await this.$store.dispatch("formError", null);
        try {
          this.showRegisteringOngoing(); // Enregistrement en cours

          const option = this.options[this.selectedOptionIndex];
          const signedUser = await this.$store.dispatch("prelaunchSignUp", {
            email: this.userSession.profile.email,
            firstName: this.userSession.profile.firstName,
            lastName: this.userSession.profile.lastName,
            option: option
          });

          this.showCheckoutOngoing(); // Communication avec prestataire

          const url = await this.$store.dispatch("prelaunchCheckout", {
            user: signedUser,
            option: option
          });

          if (url && url.length) {
            // Paid plan
            this.showCheckoutPending(async () => {
              // Lien de paiement personnel securisé
              window.open(url, "_blank");

              this.showCheckoutOngoing(); // Communication avec prestataire

              await this.$store.dispatch("paymentWaiting", {
                user: signedUser,
                url: url
              });

              this.showRegisteringDone(); // Merci !
            });
          } else {
            // Free plan
            this.showRegisteringDone(); // Merci !
          }
        } catch (error) {
          this.showErrorMessage(error);
          await this.$store.dispatch("formError", error);
        }
      }
    },

    // Success with buttons
    async showRegisteringDone() {
      const p = await this.$refs.infoPopup.show({
        style: "success",
        showAnimation: true,
        animated: true,
        message: this.$i18n.t("home.registering-done"),
        button: this.$i18n.t("global.close"),
        isButtonEnabled: true
      });
    },

    // Loading with no buttons
    async showRegisteringOngoing() {
      const p = await this.$refs.infoPopup.show({
        message: this.$i18n.t("home.registering-ongoing"),
        style: "loading",
        showAnimation: true,
        animated: true,
        button: this.$i18n.t("global.continue"),
        isButtonEnabled: false
      });
    },

    // Info with enabled button and callback
    async showCheckoutPending(callback) {
      const p = await this.$refs.infoPopup.show({
        message: this.$i18n.t("home.checkout-pending"),
        style: "info",
        showAnimation: true,
        animated: true,
        button: this.$i18n.t("global.continue"),
        buttonCallback: callback,
        isButtonEnabled: true
      });
    },

    // Loading with disabled button
    async showCheckoutOngoing(callback) {
      const p = await this.$refs.infoPopup.show({
        message: this.$i18n.t("home.checkout-ongoing"),
        style: "loading",
        showAnimation: true,
        animated: true,
        button: this.$i18n.t("global.continue"),
        isButtonEnabled: false
      });
    },

    // Error with enabled button
    async showErrorMessage(message) {
      const p = await this.$refs.infoPopup.show({
        message: message,
        style: "error",
        showAnimation: true,
        animated: false,
        button: this.$i18n.t("global.close"),
        isButtonEnabled: true
      });
    }
  }
};
</script>

