<template>
  <div class="container">
    <img class="starry-thanks" src="../assets/img/starry_oops.png" alt />
    <div class="error">
      <h1 class="title padd">{{ $i18n.t("global.oops") }}</h1>
      <div class="subtitle">{{ $i18n.t('oops.instructions') }}</div>
    </div>
    <!-- <div class="text-center padd">
      <a class="btn btn-primary" v-on:click="window.close()">
        <span>{{ $i18n.t('global.close') }}</span>
      </a>
    </div>-->
  </div>
</template>

<script>
export default {
  components: {},
  mounted() {}
};
</script>


<style lang="scss" scoped>
@import "@style/components/oops";
</style>