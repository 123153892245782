import Vue from 'vue';
import Router from 'vue-router';
import VueLogger from 'vuejs-logger';
import Home from './views_prelaunch/Home'
import Thanks from './views_prelaunch/Thanks'
import Oops from './views_prelaunch/Oops'
import Cgu from './views_prelaunch/Cgu'
import Legals from './views_prelaunch/Legals'

import { auth } from './firebase'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home
        },
        {
            path: '/thankyou',
            name: 'Thanks',
            component: Thanks
        },
        {
            path: '/oops',
            name: 'Oops',
            component: Oops
        },
        {
            path: '/cgu',
            name: 'Cgu',
            component: Cgu
        },
        {
            path: '/legals',
            name: 'Legals',
            component: Legals
        }
    ]
});
/*
router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
    const requiresGuest = to.matched.some(x => x.meta.requiresGuest)

    // console.log(requiresAuth, requiresGuest, to)

    if (requiresAuth && !auth.currentUser) {
        next('/home')
    } else if (requiresGuest && auth.currentUser) {
        next('/home')
    } else {
        next()
    }
})
*/
export default router