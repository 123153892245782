import Vue from 'vue'
import VueLogger from 'vuejs-logger'
import Vuex from 'vuex'
import * as fb from './firebase'
import router from './routes'
import i18n from './i18n'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    userSession: {
      auth: {
        record: null,
        signingIn: false
      },
      profile: null,
      checkout: {
        link: null
      },
      payment: {
        awaiting: false,
        pending: false,
        done: false
      },
      cookies: {
        acted: null,
        essentials: true,
        functionals: true,
        ads: true
      },
      error: null,
      success: null
    }
  },
  mutations: {
    setAuthUser(state, val) {
      state.userSession.auth.record = val
      Vue.$log.debug('AuthUser:', val);
    },
    setUserProfile(state, val) {
      state.userSession.profile = val
      Vue.$log.debug('UserProfile:', val)
    },
    setUserError(state, val) {
      state.userSession.error = val
      Vue.$log.debug('UserError', val)
    },
    setUserCheckoutLink(state, val) {
      state.userSession.checkout.link = val
    },
    setPaymentAwaiting(state, val) {
      state.userSession.payment.awaiting = val
      state.userSession.payment.pending = false
      state.userSession.payment.done = false
    },
    setPaymentPending(state, val) {
      state.userSession.payment.awaiting = false
      state.userSession.payment.pending = val
      state.userSession.payment.done = false
    },
    setPaymentDone(state, val) {
      state.userSession.payment.awaiting = false
      state.userSession.payment.pending = false
      state.userSession.payment.done = val
    },
    setSessionSigningIn(state, val) {
      state.userSession.auth.signingIn = val
    },
    setSuccessMessage(state, val) {
      state.userSession.success = val;
    }
  },
  actions: {
    async formError({ commit }, message) {
      commit('setUserError', message)
    },

    async paymentWaiting({ commit, dispatch, state }, data) {
      commit('setUserCheckoutLink', data.url)
      // window.open(data.url, '_blank'); // First open new tab while waiting on /payments collection
      // Creates listener on /payments and listen for a payment that succeeded for the selected product
      const waiting = async () => {
        return new Promise((resolve, reject) => {
          fb.usersCollection
            .doc(data.user.identifier)
            .onSnapshot(snapshot => {
              const user = snapshot.data()
              if (user.prelaunchCheckout) {
                if (user.prelaunchCheckout.pending) {
                  Vue.$log.debug('Waiting while /prelaunchCheckout.pending', user.prelaunchCheckout.pending)
                  commit('setPaymentPending', true)
                } else if (user.prelaunchCheckout.done) {
                  commit('setPaymentDone', true)
                  Vue.$log.debug('Checkout done at', user.prelaunchCheckout.paidAt)
                  resolve()
                } else {
                  commit('setPaymentDone', false)
                  reject(i18n.t("errors.unknown-error"))
                }
              } else {
                Vue.$log.debug('Waiting for /prelaunchCheckout node')
              }
            })
        })
      };

      try {
        return await waiting();
      } catch (error) {
        throw error
      }
    },

    /// Will initiate the stripe procedure
    async prelaunchCheckout({ commit, dispatch }, data) {
      Vue.$log.debug('prelaunchCheckout');

      const user = data.user;
      const option = data.option;

      // In case of a free plan, checkoutUrl will be empty, no need to redirect
      try {
        const checkoutUrl = await fb.processPrelaunchCheckout(user.email, option);

        if (option !== 'free') {
          return checkoutUrl
        }

        return;

      } catch (error) {
        commit('setUserError', error)
        throw error;
      }
    },

    /*
    Proceed to user registration with given email address. The code verify if user is from the same device and re-sign if needed then recursively retries.
     */
    async prelaunchSignUp({ commit, dispatch, state }, form) {
      Vue.$log.debug('PrelaunchSignUp', form);
      commit('setSessionSigningIn', true);
      try {
        var user = await fb.processPrelaunchSignup(form.email, form.firstName, form.lastName, form.option);
      } catch (error) {
        commit('setSessionSigningIn', false);
        commit('setUserError', error)
        throw error;
      }

      commit('setSessionSigningIn', false);

      if (form.option !== 'free') {
        commit('setPaymentAwaiting', true);
      } else {
        commit('setPaymentDone', true);
      }

      if (state.userSession.auth.record.uid !== user.identifier) {
        const oldUid = state.userSession.auth.record.uid;
        Vue.$log.error('Current auth is not the same as user identifier, should re-log on behaves of', user.email)
        await dispatch('loginWithUid', user.identifier)
        return dispatch('prelaunchSignUp', form)
      }

      if (state.userSession.auth.record.uid !== user.identifier) {
        console.error('Current auth is not the same as user identifier after re-login.', user.email)
        throw i18n.t("errors.invalid-authentication");
      }

      return user
    },

    async anonLogin({ commit, dispatch }) {
      await fb.auth.signInAnonymously()
    },

    async loginWithUid({ dispatch }, uid) {
      const token = await fb.getToken(uid);
      const { authUser } = await fb.auth.signInWithCustomToken(token);
    },

    async login({ dispatch }, form) {
      const { authUser } = await fb.auth.signInWithEmailAndPassword(form.email, form.password)
    },

    async signup({ dispatch }, form) {
      const { authUser } = await fb.auth.createUserWithEmailAndPassword(form.email, form.password)

      // create user object in userCollections
      await fb.usersCollection.doc(authUser.uid).set({
        name: form.name,
        title: form.title
      })
    },

    async fetchUserProfile({ commit }, authUser) {
      // fetch user profile
      const userProfile = await fb.usersCollection.doc(authUser.uid).get()
      if (userProfile.data()) {
        // set user profile in state
        commit('setUserProfile', userProfile.data())
      } else {
        const getUserUnsubscribe = fb.usersCollection.doc(authUser.uid)
          .onSnapshot(snapshot => {
            if (snapshot.data()) {
              commit('setUserProfile', snapshot.data())
              getUserUnsubscribe()
            }
          })
      }
    },

    async logout({ commit }) {
      // log user out
      await fb.auth.signOut()

      // clear user data from state
      commit('setUserProfile', null)
    }
  }
})

export default store