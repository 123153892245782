import Vue from 'vue'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'
import 'firebase/analytics'
import 'firebase/app-check'

// firebase init
const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID
}
const fireApp = firebase.initializeApp(firebaseConfig)

// utils
const db = firebase.firestore()
const auth = firebase.auth()
const functions = firebase.app().functions("europe-west1")
const analytics = firebase.analytics();

const appCheckScript = document.createElement("script");
appCheckScript.innerHtml = "self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;";
document.head.append(appCheckScript)
const appCheck = firebase.appCheck();

// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
appCheck.activate(
    process.env.VUE_APP_FIREBASE_APP_CHECK_ID,
    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    true);

// collection references
const usersCollection = db.collection('users')
const customersCollection = db.collection('customers')

// Public api
const axiosBase = require('axios')
var axios = null;
var token = '';

/*
Listen to the auth user and updated the current idToken used by axios
*/
auth.onAuthStateChanged(user => {
    if (auth.currentUser) {
        auth.currentUser.getIdToken(true)
            .then(newToken => {
                token = newToken;
                axios = axiosBase.create({
                    baseURL: process.env.VUE_APP_FIREBASE_FUNCTIONS_ENDPOINT,
                    timeout: 60000,
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Access-Control-Allow-Origin': '*'
                    }
                });
                Vue.$log.debug("Axios with token", token);
            })
    }
});

async function post(url, body) {
    return new Promise((resolve, reject) => {
        axios.post(url, body)
            .then(result => resolve(result.data))
            .catch(error => {
                if (error.response && error.response.data.message) {
                    reject(error.response.data.message)
                } else {
                    reject("Une erreur est survenue")
                }
            })
    })
}

/*
Used to re-sign in behaves of uid
 */
async function getToken(uid) {
    const result = await post('/api/prelaunch/token', { uid: uid });
    console.info("/token", result)
    return result
}

/*
Returns the /users node (profile) corresponding to that email
 */
async function processPrelaunchSignup(email, firstName, lastName, option) {
    const result = await post('/api/prelaunch/signup', {
        email: email,
        firstName: firstName,
        lastName: lastName,
        option: option
    });
    console.info("/signup", result)
    return result
}

/*
Returns the checkout url for user payment or empty if option is 'free'
 */
async function processPrelaunchCheckout(email, option) {
    const result = await post('/api/prelaunch/checkout', {
        email: email,
        option: option
    });
    console.info("/checkout", result)
    return result
}

/*
Returns 200 OK if deletion of uid succeeded
 */
async function processPrelaunchCleanUp(uid) {
    const result = await post('/api/prelaunch/cleanup', { uid: uid });
    console.info("/cleanup", result)
    return result
}

export {
    db,
    auth,
    functions,
    analytics,
    appCheck,
    usersCollection,
    customersCollection,
    getToken,
    processPrelaunchSignup,
    processPrelaunchCheckout,
    processPrelaunchCleanUp
}