<script>
export default {
  created() {},
  mounted() {
    document.title = "Stowy";
    var favicon = document.createElement("link");
    favicon.rel = "icon";
    favicon.type = "image/ico";
    favicon.href = "./assets/img/favicon.ico";
    document.head.append(favicon);
  }
};
</script>

<template>
  <div id="app"></div>
</template>


<!-- This should generally be the only global CSS in the app. -->
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700&display=swap");

// Style variables and utilities from src/style.
@import "@style/vars";

// App common Style
@import "@style/common";
</style>
